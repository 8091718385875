<template>
  <Popup :margin="margin" :radiusType="radiusType" :closePopupEvent="closePopup">
    <div class="align-view-ticket" slot="component">
      <div class="ticket-history-header align" slot="header">
      <h4 class="ft-primary">{{ $t("Details") }}</h4>
      <h3 class="ft-primary ticket-id" v-if="ticketInfo">
        #{{ ticketInfo.requestid }}
      </h3>
    </div>

    <div slot="component">
      <div class="loading-sec" v-if="isTicketLoading">
        <Loading></Loading>
      </div>
      <div v-else>
        <div class="ticket-history-content">
          <div class="align">
            <p class="ticket-title">
              <span class="ft-primary ft-medium">{{ $t("title") }} : </span
              >&nbsp;
              <span class="ft-secondary" v-if="ticketInfo">{{ $t(ticketInfo.title)}}</span>
            </p>
            <p class="ft-secondary" v-if="ticketInfo">
              {{ displayDateFormat(ticketInfo.created) }}
            </p>
          </div>
          <div>
            <p>
              <span class="ft-primary ft-medium">{{ $t("category") }} : </span
              >&nbsp;
              <span class="ft-secondary" v-if="ticketInfo">{{ $t(getCategoryName(ticketInfo.category)) }}</span>
            </p>
            <!-- <p class="ft-primary ft-medium">Description:</p>
          <p class="ft-secondary">
            Hove you ever finally just gave in to the temptation and read your
            horoscope gave in to the temptation.
          </p> -->
          </div>
        </div>
        <div class="ticket-history-section" v-if="ticketInfo">
          <p class="ticket-history-section-title">{{ $t("COMMENTS") }}</p>
          <div class="ticket-history-section-contain">
            <div class="ticket-history-section-description" v-for="(item, i) in ticketInfo.messageData" :key="i">
              <div class="align">
                <p class="ft-primary ft-medium">{{$t(item.updatedbyname)}}</p>
                &nbsp;
                <p class="ft-secondary">
                  {{ displayDateFormat(item.messagedate) }}
                </p>
              </div>
              <p class="ft-secondary" v-html="item.message"></p>
              <a href="abv.jpg" download style="display:none" id="downloadImage">
                  <img :src="imageUrl"  width="104" height="142" target>
              </a>
              <div class="item-docs" v-if="item.documents">
                <div class="item-docs-image" v-for="(img, i) in item.documents" :key="i">
                  <!-- <img @click="redirectTo(img)" :src="img.fileurl" alt /> -->
                  <img src="@/assets/icons/Download.svg" @click="redirectTo(img, item)" />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="form-group form-error">
          <div class="error-container" v-if="formError">
            <p class="error">{{ formError }}</p>
          </div>
        </div>
        <div class="ticket-history-footer">
          <div v-if="ticketInfo.requeststatus !== 'CLOSED'" class="ticket-history-footer-align">
            <input type="text" class="input" v-model="ticketComment" :placeholder="$t('Add Comment')" />
            <div
              class="new-comment"
              :class="{
                default: localDisplayLang === 'eng' ? true : false,
                flipped: localDisplayLang === 'ara' ? true : false,
              }"
            >
              <input
                type="file"
                class="file-input"
                value=""
                @change="attachFile($event)"
                accept="image/jpeg, image/png"
              />
              <img src="@/assets/icons/interface.svg" alt />
            </div>
            <button
              class="button-primary send"
              :class="[
                providerUniqueId === 'z01x1wPo' ? 'primary-nammaflix-button' : 'primary-net5-button',

                localDisplayLang === 'eng' ? 'default' : 'flipped',
              ]"
              @click="updateTicket"
            >
              {{ $t("submit") }}
            </button>
            <!-- <div v-if="showLoader">
              <Loading></Loading>
            </div> -->
          </div>
          <!-- <div
            class="ticket-history-footer-attachments"
            v-if="imagesUploadedArr.length !== 0"
          >
            <div
              class="ticket-history-footer-attachments-item"
              v-for="(file, index) in imagesUploadedArr"
              :key="index"
            >
              <div class="ticket-history-footer-attachments-item-filename">
                {{ file.name }}
              </div>
              <div
                class="ticket-history-footer-attachments-item-delete"
                @click="deleteAttachment(file)"
              >
                <img src="@/assets/icons/ClosePopup.svg" alt />
              </div>
            </div>
          </div> -->

          <div class="tickets-attachments" v-if="imagesUploadedArr.length !== 0">
            <div class="tickets-attachments-file" v-for="(image, index) in imagesUploadedArr" :key="index">
              <div class="tickets-attachments-file-details">
                <!-- <div class="tickets-attachments-file-details-image">
                <img :src="image.pic" alt />
              </div> -->
                <div class="tickets-attachments-file-details-name">
                  <span class="tickets-attachments-file-name">{{ image.name }}</span>
                </div>
              </div>
              <img class="delete-file" src="@/assets/icons/ClosePopup.svg" @click="deleteAttachment(image)" alt />
            </div>
          </div>

          <div v-if="showLoader">
            <Loading></Loading>
          </div>
        </div>
      </div>
    </div>
    </div>
    
  </Popup>
</template>

<script>
import { eventBus } from "@/eventBus";
import Utility from "@/mixins/Utility.js";
import { mapGetters, mapActions } from "vuex";
import { _providerId } from "@/provider-config.js";

export default {
  props: {
    closePopup: {
      type: Function,
    },
    ticket: {
      type: Object,
    },
    selectedTab: {
      type: String,
    },
  },
  data() {
    return {
      width: "40%",
      margin: "5% auto",
      radiusType: "full",
      ticketInfo: null,
      ticketComment: "",
      fileAttachments: [],
      showLoader: false,
      formError: null,
      providerUniqueId: _providerId,
      isTicketLoading: false,
      localDisplayLang: null,
      imagesUploadedArr: [],
      attachmentedFiles: [],
      imageUrl: ''
    };
  },

  watch: {
    fileAttachments() {},
    formError(val) {
      if (val) {
        setTimeout(() => {
          this.formError = null;
        }, 2000);
      }
    },

    attachmentedFiles(val) {
      if (val && val.length > 0) {
        if (this.imagesUploadedArr.length === val.length) {
          let payload = {
            details: {
              message: this.ticketComment,
            },
            requestid: this.ticketInfo.requestid,
          };

          if (this.attachmentedFiles.length) {
            payload.details.filename = JSON.stringify(this.attachmentedFiles);
          }

          
          // this.createTicket(true);
          eventBus.$emit("update-current-ticket", payload);
          this.showLoader = false;
        }
      }
    },
  },

  computed: {
    ...mapGetters(["appConfig"]),
  },

  created() {
    

    this.isTicketLoading = true;
    this.imagesUploadedArr = [];
    this.localDisplayLang = localStorage.getItem("setDisplayLanguageCode");

    let payload = {
      requestid: this.ticket.requestid,
    };

    eventBus.$emit("fetch-ticket", payload);
  },

  mounted() {
    

    eventBus.$on("get-ticket-response", (data) => {
      if (!data.data.errorcode && !data.data.reason) {
        this.ticketInfo = data.data;
        this.isTicketLoading = false;
      } else {
        this.isTicketLoading = false;
        this.$emit("dismissPopup");
      }
    });

    eventBus.$on("update-Ticket-response", (response) => {
      if (response.success) {
        this.$emit("refreshList");
        this.closePopup();
      }
    });
  },

  methods: {
    ...mapActions(['fetchTicketAttachemnets']),
    redirectTo(image, item) {
        this.imageUrl = image.fileurl
        let tagItem  = document.getElementById('downloadImage')

          tagItem.href = image.fileurl; //Image Base64 Goes here
          tagItem.download = image.attachmentid + ".png"; //File name Here
          tagItem.click(); //Downloaded file
          
      // });

      console.error("image==>>", image, item)
      // window.open(image.fileurl, "_self");
    },
//     redirectTo(image, item) {
//     const imageUrl = image.fileurl;

//     // Fetch the image as binary data
//     fetch(imageUrl, { method: 'GET', responseType: 'arraybuffer' })
//         .then(response => response.arrayBuffer())
//         .then(data => {
//             const blob = new Blob([data], { type: 'application/octet-stream' });

//             // Create a temporary link element
//             const link = document.createElement('a');
//             link.href = window.URL.createObjectURL(blob);
//             link.download = image.attachmentid + '.png';

//             // Append the link to the document and trigger a click
//             document.body.appendChild(link);
//             link.click();

//             // Remove the link from the document
//             document.body.removeChild(link);
//         })
//         .catch(error => {
//             console.error('Error fetching image:', error);
//         });
// }



    deleteAttachment(value) {
      let getIndex = this.imagesUploadedArr.findIndex((el) => el.name === value.name);
      console.log("Delete file index", getIndex)
      if (getIndex > -1) {
        this.imagesUploadedArr.splice(getIndex, 1);
      }
    },

    uploadImageAttachments() {
      if (this.imagesUploadedArr && this.imagesUploadedArr.length > 0) {
        

        this.imagesUploadedArr.forEach((image, index) => {
          let fileObj = {
            isTicket: true,
            index: index,
            params: {
              filename: image,
            },
          };
          

          eventBus.$emit("fileUpload", fileObj);

          eventBus.$on(`file-upload-response-${index}`, (data) => {
            this.attachmentedFiles.push(data.success);
            
            eventBus.$off(`file-upload-response-${index}`);
          });
        });
      }
    },

    updateTicket() {
      if (!this.validateMessage(this.ticketComment)) {
        return;
      }

      this.showLoader = true;

      let payload = {
        details: {
          message: this.ticketComment,
        },
        requestid: this.ticketInfo.requestid,
      };

      if (this.imagesUploadedArr && this.imagesUploadedArr.length > 0) {
        
        this.uploadImageAttachments();
      } else {
        
        eventBus.$emit("update-current-ticket", payload);
        this.showLoader = false;
      }
    },

    hasWhiteSpace(s) {},

    validateMessage(value) {
      this.formError = null;

      const newValue = value.trim();

      if (!newValue) {
        this.formError = this.$t("Comment Required");
        return false;
      }
      return true;
    },
  },
  components: {
    Popup: () => import(/* webpackChunkName: "Popup" */ "@/components/Slots/Popup.vue"),
    Loading: () => import(/* webpackChunkName: "Loading" */ "@/components/Templates/Loading.vue"),
  },
  mixins: [Utility],

  beforeDestroy() {
    eventBus.$off("get-ticket-response");
    eventBus.$off("update-Ticket-response");
  },
};
</script>

<style lang="scss">
@import "@/sass/_variables.scss";
@import "@/sass/_components.scss";
@import "./ticketHistory.scss"

</style>
